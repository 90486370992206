<template>
  <div>
    <div>感谢你的支持，咖啡就不喝了，如果你是小白，对此项目有很多疑惑，可以加入我的知识星球，跟我一起学习。<br><br>
      知识星球限时特惠中，扫码即可加入
    </div>

    <img src="@/assets/星球-小.png" alt="" style="margin: 10px 0">

  </div>
</template>

<script>
export default {
  name: "Donate"
}
</script>

<style scoped>

</style>
